<template>
  <div class="create-contact">
    <ContactAppbar />
    <div class="ma-4">
      <ContactForm />
    </div>
  </div>
</template>
<script>
/**
 * ==================================================================================
 * Create Contact
 * ==================================================================================
 **/

import ContactAppbar from '@/views/Home/Hub/Contacts/components/ContactAppbar'
import ContactForm from '@/views/Home/Hub/Contacts/components/ContactForm'

export default {
  components: {
    ContactAppbar,
    ContactForm,
  },
}
</script>
<style lang="scss">
.create-contact {
  //
}
</style>
