<template>
  <Navbar name="Contact Directory" redirect="hub.contact-directory" />
</template>
<script>
/**
 * ==================================================================================
 * Hub Contacts Appbar
 * ==================================================================================
 **/

import Navbar from '@/components/Navbar'

export default {
  components: {
    Navbar,
  },
}
</script>
